import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"

const SecondPage = () => (
  <Layout>
    <SEO title="Full Video" />
    <h1>Full Video</h1>
    <p>Enjoy the full video</p>
    <div style={{ maxWidth: `1000px`, marginBottom: `1.45rem` }}>
      <Video url='https://d26914svare7a0.cloudfront.net/SAJ_2020_full.mp4' />
    </div>
    <Link to="/">Homepage</Link>
  </Layout>
)

export default SecondPage
